<style lang="scss">
.modal-button {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
</style>

<template>
  <div class="modal-button" :class="{ open: isVisible }" v-bind="props" @click="showModal">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const button = ref(null);
    const store = useStore();

    const showModal = () => {
      store.state.modalstate[props.link] = !store.state.modalstate[props.link];
    };

    const isVisible = computed(() => store.state.modalstate[props.link]);

    return {
      props,
      button,
      showModal,
      isVisible,
    };
  },
});
</script>
