
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const button = ref(null);
    const store = useStore();

    const showModal = () => {
      store.state.modalstate[props.link] = !store.state.modalstate[props.link];
    };

    const isVisible = computed(() => store.state.modalstate[props.link]);

    return {
      props,
      button,
      showModal,
      isVisible,
    };
  },
});
